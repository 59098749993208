<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Reactivity" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 md:pr-2"></div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            These tests are designed to test and evaluate the waste material in
            question to determine if one or more of the characteristics of
            reactivity exist for that material. Not all of the tests are always
            required for a given substance. The list below is a partial listing
            only. Some of the tests are discussed in other sections.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Explosive Cap (No. 8) Testing" />
        <Paragraph
          ><p>
            The No. 8 cap test is used to determine susceptibility of explosives
            to detonation from the energy delivered by a No. 8 electric blasting
            cap. The test consists of a sample approximately 60 cubic inches in
            size placed in a cardboard tube. (Liquid samples are placed in a
            polypropylene bottle.) The tube is placed on top of a steel witness
            plate with a No. 8 cap inserted at the top of the cardboard tube.
            Sample detonation is determined by examining the witness plate the
            criteria for detonation is that the witness plate is torn or
            penetrated. This test is part of the series conducted to establish
            propellant hazard classification for shipping and for
            quantity-distance siting. This test can also be completed by using a
            lead cylinder as a witness device by placing a cardboard tube 80 mm
            in diameter by 160 mm long on top of a common (soft) lead cylinder
            (51-mm O.D. x 102-mm). Sample detonation is determined by examining
            the lead cylinder; compression from its initial length by 3.2 mm or
            more indicates a detonation.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Hazardous When Wet" />
        <Paragraph
          ><p>
            This is a series of four tests beginning with a very small sample
            and gradually increasing in size up to 25 grams. The sample material
            is exposed to varying degrees of moisture and the reaction or gas
            evolution is observed and measured. Wetted materials that do not
            spontaneously ignite nor release more than 1 liter of flammable or
            toxic gas per hour per kilogram of material are not considered
            Dangerous When Wet.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Ignition and Unconfined Burn" />
        <Paragraph
          ><p>
            This test is used to determine if samples, once ignited continue
            burning or transit to an explosion or detonation and helps establish
            the unconfined burning properties of explosives. A 125 gram sample
            is placed on a bed of sawdust soaked with kerosene or diesel fuel
            approximately 30 cm square and 1.3-2.5 cm thick. The sawdust is
            ignited with an electric match or similar device and the burn time
            is measured. The test is performed three times unless an explosion
            or detonation occurs.
          </p>
          <p>
            This test is also conducted using four 2-inch cubed samples
            end-to-end in a single row as part of the series of tests conducted
            to establish hazard classification for shipping and
            quantity-distance siting.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Internal Ignition" />
        <Paragraph
          ><p>
            This test is designed to determine the response of an explosive to
            rapidly rising temperatures and pressures. The sample, with an
            initial temperature of 25°C, is loaded into a schedule 80 pipe with
            forged steel end caps. A black powder bag igniter is inserted into
            the center of the pipe and the leads are sealed with epoxy resin.
            The igniter is fired and the results are assessed. Either the pipe
            or at least one of the end caps must be fragmented into at least two
            distinct pieces for a positive result.
          </p>
          <p>
            The test is considered negative (the material passes) if the pipe is
            merely split open or the caps are blown off in one piece. Three
            trials are performed unless a positive result occurs earlier. The
            test determines if a material will explode or detonate when ignited
            under confinement.<br />
            Reference: Transport of Dangerous Goods – Tests and Criteria
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Modified Unconfined Burn (Oxidizers)" />
        <Paragraph
          ><p>
            Dry sawdust is mixed with a specified, known oxidizer and ignited
            under specified temperature and humidity criteria. The time to
            consume the sawdust mixture is recorded. The sample material is then
            mixed with the sawdust in two specified ratios. Each ratio is tested
            three times by igniting the mixture and timing the burn duration. If
            the average burn times of both mix ratios are greater than that
            observed with the known oxidizer, the material is not classed as an
            oxidizer. This test is also used for DOT classification of materials
            as oxidizers.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Single Package" />
        <Paragraph
          ><p>
            This test determines if there is a mass explosion hazard of the
            contents of a single package. The package is placed on a steel
            witness plate on the ground, and confined using containers that are
            similar in shape and size to the test package and completely filled
            with earth or sand. The substance or article is initiated and the
            following observations are made: evidence of thermal effects,
            projection effects, detonation, deflagration or explosion of the
            total package contents. This test is performed three times unless a
            decisive result occurs earlier (e.g. explosion of the total
            contents, damage to witness plate beneath the package, etc.).
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/4"></div>
      <div class="w-full md:w-1/2 mb-6">
        <TitleComponent title="Zero Gap, Card Gap" />
        <Paragraph
          ><p>
            The sample is loaded into the specified steel tubes and placed
            facing a steel witness plate. A pentolite booster is used to provide
            a known shock source. The damage to the tube and witness plate is
            the criteria for a “go” or a “no-go” reaction.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/4"></div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Material Reactivity",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS is experienced in conducting tests designed to evaluate waste material reactivity. This includes Explosive Cap (No. 8), Hazardous When Wet, Ignition and Unconfined Burn, Internal Ignition, Single Package, and Gap Tests."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
